




















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'HeaderInfo'
})
export default class extends Vue {
  @Prop() naire!: Questionnaire.INaire
}
